import { Card, Table } from "antd";
import React, { useEffect } from "react";
import Filterbox from "../../ui/FilterBox/Filterbox";
import SapaHeader from "../../ui/Header/Header";
import { apiProvidersColumn } from "../../DummyAPI/TableData/Columns";
import ProgressLoader from "../../components/Loader/ProgressLoader";
import { useDispatch, useSelector } from "react-redux";
import { selectProvidersData, selectProvidersLoading } from './../../redux/selectors/providerSelectors';
import { listProviders } from './../../redux/actions/provider';
import { formatDate } from "../../utils/utils";

const ApiProviders = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectProvidersLoading);
  const providerList = useSelector(selectProvidersData);

  const tableStyle = {
    tableLayout: "fixed",
  };

  // adding classname to odd table rows
  const rowClassName = (record, index) => {
    return index % 2 === 1 ? "odd-row" : "";
  };

  useEffect(() => {
    dispatch(listProviders());
  }, [dispatch]);

  const prepareDataToExport = () => {
    let data = providerList.map(item => {
      return {
        "Provider Name": item?.name,
        "Description": item?.description,
        "Date": formatDate(item?.created_at)
      };
    });

    return data;
  };


  return (
    <ProgressLoader isLoading={isLoading}>
      <div className="ApiProviders">
    
        <SapaHeader page={"API Providers"} />
        <Card className="sapa-apiproviders__container">
          <Filterbox 
            displayFilter={"none"}
            searchIsLoading={isLoading}
            searchAction={listProviders}
            exportFileName="providers.csv"
            dataToExport={prepareDataToExport}
            showExportButton={false}
          />
          <Table
            dataSource={providerList}
            columns={apiProvidersColumn}
            pagination={false}
            rowClassName={rowClassName}
            style={tableStyle}
          />
        </Card>
      </div>
    </ProgressLoader>
  );
};

export default ApiProviders;
