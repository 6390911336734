import React, { useState } from 'react';
import { Select } from 'antd';

const { Option } = Select;

const SpPagination = ({ total = 20, defaultCurrent = 1, pageSize = 10, onChange }) => {
  const [currentPage, setCurrentPage] = useState(defaultCurrent);

  const totalPages = Math.ceil(total / pageSize);

  const handleSelectChange = (value) => {
    setCurrentPage(value);
    

    if (typeof onChange === "function") {
        onChange(value);
    }
  };

  const pageOptions = Array.from({ length: totalPages }, (_, i) => i + 1);

  const startItem = (currentPage - 1) * pageSize + 1;
  const endItem = Math.min(currentPage * pageSize, total);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', margin: "32px 24px" }}>
      {/* Custom Select for page navigation (aligned to the left) */}
      <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
        Page 
        <Select
          value={currentPage}
          style={{ width: 70, borderRadius: 8 }}
          onChange={handleSelectChange}
        >
          {pageOptions.map(page => (
            <Option key={page} value={page}>
              {page}
            </Option>
          ))}
        </Select> 
        of {totalPages}
      </div>

      {/* Showing x of y (aligned to the right) */}
      <div style={{ marginRight: 24 }}>
        Showing {startItem} of {total}
      </div>
    </div>
  );
};

export default SpPagination;
