import { createReducer } from '@reduxjs/toolkit';
import {
  listServices,
  filterServices,
  searchServices,
  exportServices,
  switchProvider,
} from '../actions/service';

const initialState = {
  services: {
    data: [],            // List of services (includes filtered or search results)
    currentPage: 1,      // Current page for pagination
    totalRecords: 0,       // Total number of pages
    pageSize: 10,        // Items per page
  },
  loading: false,        // Loading state for listing, filtering, or searching services
  error: null,           // General error state

  // Export state as an object
  export: {
    loading: false,      // Loading state for export action
    success: false,      // Tracks if export was successful
    error: null,         // Export-specific error
  },
  switchProvider: {
    loading: false,
  }
};

const servicesReducer = createReducer(initialState, {
  // Listing services (with pagination)
  [listServices.trigger]: (state) => {
    state.loading = true;
    state.error = null;
  },
  [listServices.resolved]: (state, action) => {
    const { data: services, currentPage, totalRecords, pageSize } = action.payload;
    
    state.loading = false;
    state.services = {
      data: services,
      currentPage,
      totalRecords,
      pageSize,    
    };
  },
  [listServices.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload; 
    state.services = {};
  },

  // Filter services (handled in the backend)
  [filterServices.trigger]: (state) => {
    state.loading = true;
    state.error = null;
  },
  [filterServices.resolved]: (state, action) => {
    
    const { 
      data: services, 
      current_page: currentPage, 
      next_page_url: nextPageUrl, 
      prev_page_url: prevPageUrl, 
      total: totalRecords, 
      per_page: pageSize 
    } = action.payload;


    state.loading = false;
    state.services = {
      data: services,    // Set filtered services data
      currentPage,       // Update pagination after filtering
      totalRecords,
      pageSize,
      nextPageUrl,
      prevPageUrl
    };
  },
  [filterServices.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload; // Handle filtering error
  },

  // Search services (handled in the backend)
  [searchServices.trigger]: (state) => {
    state.loading = true;
    state.error = null;
  },
  [searchServices.resolved]: (state, action) => {
    const { services, currentPage, totalRecords, pageSize } = action.payload;
    state.loading = false;
    state.services = {
      data: services,    // Set search results
      currentPage,       // Update pagination after search
      totalRecords,
      pageSize,
    };
  },
  [searchServices.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload; // Handle search error
  },

  // Export services (managed as a separate object)
  [exportServices.trigger]: (state) => {
    state.export.loading = true;  // Set export-specific loading
    state.export.success = false; // Reset success status
    state.export.error = null;    // Clear export errors
  },
  [exportServices.resolved]: (state) => {
    state.export.loading = false; // Stop export loading
    state.export.success = true;  // Set export success flag
  },
  [exportServices.rejected]: (state, action) => {
    state.export.loading = false; // Stop export loading
    state.export.success = false; // Mark export as unsuccessful
    state.export.error = action.payload; // Handle export error
  },


  // Switch provider (managed as a separate object)
  [switchProvider.trigger]: (state) => {
    state.switchProvider.loading = true; 
  },
  [switchProvider.resolved]: (state) => {
    state.switchProvider.loading = false;
  },
  [switchProvider.rejected]: (state) => {
    state.switchProvider.loading = false;
  },

});

export default servicesReducer;
