import { Space, Typography, Card } from "antd";
import React, { useEffect } from "react";
import SapaHeader from "../../ui/Header/Header";
import Dashboard_LineChart from "./Dashboard-LineChart";
import DashboardCard from "./DashboardCard";
import legend from "../../assets/media-icons/Group 1.png";
import calendar from "../../assets/svgs/Dashboard-icons/calendar.svg";
import ProgressLoader from "../../components/Loader/ProgressLoader";
import { useDispatch, useSelector } from "react-redux";
import { selectDashboardLoading, selectDashboardStatistics } from "../../redux/selectors/dashboardSelectors";
import { fetchDashboardStatistics } from './../../redux/actions/dashboard';
import ActionHandler from "../../components/ActionHandler/ActionHandler";
//import { ChartData } from './../../DummyAPI/ChartData/ChartData';


const Dashboard = () => {
  const dispatch = useDispatch();
  const dStats = useSelector(selectDashboardStatistics);
  const isLoading = useSelector(selectDashboardLoading);

  const chartData = dStats?.revenue_chart || [];

  const statsData = {
    labels: chartData.map((data) => data.month),
    datasets: [
      {
        label: "Profit",
        data: chartData.map((data) => data.profit),
        borderColor: "#318D1F",
      },
      {
        label: "Revenue",
        data: chartData.map((data) => data.revenue),
        borderColor: "#2F75FF",
      },
    ],
  };


  useEffect(() => {
    dispatch(fetchDashboardStatistics());
  }, [dispatch]);


  return (
    <ProgressLoader isLoading={isLoading}>
      <div className="Dashboard">
        <SapaHeader page="Dashboard" />
        <Space
          className="sapa-dashboard__topSection"
          direction="vertical"
          size={24}
        >
          <Typography.Text>Overview</Typography.Text>
          <div className="sapa-dashboard-card__container">
            <DashboardCard
              title="Total Income"
              value={`₦ ${dStats?.total_income || 0}`}
            />
            <DashboardCard
              title="Total Transactions"
              value={`${dStats?.total_transactions || 0}`}
            />
            <DashboardCard
              title="Total Users"
              value={dStats?.total_users || 0}
            />
            <DashboardCard
              title="Profit Margin"
              value={`₦ ${dStats?.profit_margin || 0}`}
            />
          </div>
        </Space>
        <Space
          className="sapa-dashboard__topSection"
          direction="vertical"
          size={24}
        >
          <Typography.Text>Income Flow</Typography.Text>
          <Card className="sapa-dashboard__lowerSection">
            <div className="sapa-dashboard__legend_container" style={{marginBottom: 32, marginTop: 16}}>
              <img className="chart-img" src={legend} alt="/" />
              <ActionHandler
                isDropdown={true}
                customIcon={<img className="calendar-icon" src={calendar} alt="/" />}
                items={[
                  { label: "All", value: "all" },
                  { label: "Last Week", value: "last_week"},
                  { label: "Last Month", value: "last_month" },
                  { label: "Last 6 Months", value: "last_6_months" },
                  { label: "Last Year", value: "last_year"}
                ]}
                callback={(e) => {
                  dispatch(fetchDashboardStatistics({date: e?.value}))
                }}
              />
            </div>
            <Dashboard_LineChart chartData={statsData} />
          </Card>
        </Space>
      </div>
    </ProgressLoader>
  );
};

export default Dashboard;
