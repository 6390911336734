import React from "react";
import SpDrawer from "../SpDrawer/SpDrawer";
import SpInput from "../../SpInput/SpInput";
import SpSelect from "../../SpSelect/SpSelect";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { listProviders, updateProviderBalance } from './../../../redux/actions/provider';
import { message } from "antd";
import { selectUpdateBalanceLoading } from "../../../redux/selectors/providerSelectors";

const ApiProviderModal = ({ onClose, open, data }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectUpdateBalanceLoading);

  const formik = useFormik({
    initialValues: {
      operation_type: '',
      amount: '',
      id: data?.id,
    },
    onSubmit: (values) => {
      dispatch(updateProviderBalance(values)).then((res) => {
        message.success(res?.message);
        dispatch(listProviders());
      }).catch((err) => {
        message.error(err);
      });
    }
  });

  const handleSaveChanges = () => {
    // Manually trigger form submission
    formik.submitForm();
  };

  const handleChange = (key, value) => {
    formik.setFieldValue(key, value); // Use Formik's setFieldValue to update form state
  };

  return (
    <SpDrawer
      title="Update Balance"
      onClose={onClose}
      open={open}
      onSaveChanges={handleSaveChanges} // Trigger form submission on Save
      saveButtonLoading={isLoading}
    >
      <form onSubmit={formik.handleSubmit}>
        <div style={{ marginBottom: 24 }}>
          <SpSelect
            htmlFor="updateType"
            label="Update Type"
            options={[
              { label: "Credit", value: 'credit' },
              { label: "Debit", value: 'debit' },
            ]}
            value={formik.values.operation_type}
            onChange={(value) => handleChange('operation_type', value)}
          />
        </div>

        <div style={{ marginBottom: 24 }}>
          <SpInput
            htmlFor="amount"
            label="Amount"
            value={formik.values.amount}
            onChange={(e) => handleChange('amount', e.target.value)}
          />
        </div>
      </form>
    </SpDrawer>
  );
};

export default ApiProviderModal;
