import { Input } from "antd";
import SpLabel from "../SpLabel/SpLabel";

const SpInput = ({ label, htmlFor, id, placeholder, onChange }) => {
    return (
        <>
            {label && <SpLabel htmlFor={htmlFor}>{label}</SpLabel>}
            <Input
                id={label? htmlFor : id}
                className="sp-input"
                placeholder={placeholder}
                onChange={onChange}
            />
        </>
    );
}

export default SpInput;