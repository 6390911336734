import { Avatar } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import React, { useRef, useState } from "react";
import CheckCircle from "../../../../assets/svgs/svg-icons/Check-circle";
import CopyIcon from "../../../../assets/svgs/svg-icons/Copy-icon";
import SapaButton from "../../../../ui/Button/Button";
import { formatDate } from './../../../../helpers/date';
import { formatAmount } from './../../../../helpers/numbers';
import { capitalize } from "lodash";
import { useReactToPrint } from "react-to-print";
import PrintScreen from "../../../PrintScreen/PrintScreen";

const TransactionDetails = ({ data }) => {

  const printRef = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const {
    req,
    res,
    created_at: createdAt = "N/A",
    transaction_reference: reference = "N/A",
    //total_amount: totalAmount = "0.00",
    amount = "0.00",
    transaction_fee: transactionFee = "0.00",
    payment_method: paymentMethod,
    status,
    service = {}
  } = data || {};

  const {
    account = "N/A",
    name: beneficiaryName = "N/A",
    bank_name: beneficiaryBank,
    service_type: transactionType,
    remark = "N/A",
    customer_address: beneficiaryAddress,
  } = req || {};

  const {
    token
  } = res?.info || {};

  const {
    image_url: imageUrl
  } = service || {};

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `${data?.transaction_reference}`,
    onBeforeGetContent: () => {
      setIsLoading(true);
    },
    onAfterPrint: () => {
      setIsLoading(false);
    }
  });


  return (
    <>
      <Avatar className="modal-avatar" src={imageUrl} alt="/" />
      <div className="transaction-modal-list__container">
        <div>
          <span>
            <p>Date & Time</p>
            <p className="modal-list-dark-text">{formatDate(createdAt)}</p>
          </span>
          <span>
            <p>Beneficiary</p>
            <div className="modal-list-long">
              <pre className="modal-list-dark-text">{account}</pre>
              <pre>{beneficiaryName}</pre>
              {beneficiaryBank && <pre>{beneficiaryBank}</pre>}
              {beneficiaryAddress && <div style={{
                maxWidth: 200,
                display: "inline-block",
                textAlign: "right",
                marginLeft: 100,
                fontSize: 14
              }}>{beneficiaryAddress}</div>}
            </div>
          </span>
          <span>
            <p>Token</p>
            <Paragraph
              className="modal-list-dark-text"
              copyable={{
                icon: [
                  <CopyIcon key="copy-icon" />,
                  <CheckCircle key="copied-icon" />,
                ],
                text: { token },
              }}
            >
              {token}
            </Paragraph>
          </span>
          <span>
            <p>Reference</p>
            <p className="modal-list-dark-text">{reference}</p>
          </span>
          <span>
            <p>Transaction Type</p>
            <p className="modal-list-dark-text">{capitalize(transactionType)}</p>
          </span>
          <span>
            <p>Amount</p>
            <p className="modal-list-dark-text">{formatAmount(amount, "₦")}</p>
          </span>
          <span>
            <p>Service Charge</p>
            <p className="modal-list-dark-text">{formatAmount(transactionFee, "₦")}</p>
          </span>
          <span>
            <p>Remark</p>
            <p className="modal-list-dark-text">{remark}</p>
          </span>
          <span>
            <p>Status</p>
            <p className={`modal-list-${status?.toLowerCase()}-text`}>{capitalize(status)}</p>
          </span>
          <span>
            <p>Payment Method</p>
            <p className="modal-list-dark-text">{paymentMethod}</p>
          </span>
        </div>
        <div className="transaction-modal-btn__container">
          <SapaButton
            label={"Download"}
            shape="rounded"
            buttonType="primary"
            loading={isLoading}
            onClick={handlePrint}
          />
          <SapaButton
            label={"Refund"}
            shape="rounded"
            buttonType="transparent"
          />
        </div>
      </div>
      <PrintScreen
        ref={printRef}
        txData={data}
      />
    </>
  );
};

export default TransactionDetails;
