

const SpTab = ({ items, activeIndex = 0, onChange }) => {
    return (
        <div className="sp-tab">
            {items?.map((label, index) => (
                <button
                    key={index}
                    //onClick={() => handleTabClick(index)}
                    className={activeIndex === index ? "active" : ""}
                    onClick={() => onChange({index, label})}
                >
                    {label}
                </button>
            ))}
        </div>
    );
}

export default SpTab;