
import CommissionTable from "../../../ui/Tables/CommissionTable";
import { useLocation } from "react-router-dom";

const UserCommissions = ({ data }) => {

    const location = useLocation();

    return (
        <CommissionTable data={location.state} />
    );
}


export default UserCommissions;