import React from "react";
import { Button } from "antd";
import PropTypes from "prop-types";
import _noop from "lodash/noop";

const SapaButton = ({
  label,
  style,
  shape = "default",
  customClass,
  onClick,
  icon,
  displayIcon,
  buttonType = "primary",
  htmlType,
  loading,
}) => {
  let buttonStyle = "";
  switch (buttonType) {
    case "primary":
      buttonStyle = "button__primary";
      break;
    case "transparent":
      buttonStyle = "button__transparent";
      break;
    default:
      break;
  }
//   const styles = {
//     display: displayIcon 
// }
  return (
    <Button
      className={`button ${buttonStyle} ${customClass}`}
      type="primary"
      shape={shape}
      size={20}
      onClick={onClick}
      style={style}
      htmlType={htmlType}
      loading={loading}
    >
      <span>
         {!loading && displayIcon && (<img src={icon} alt="/" />) }
        {label}
      </span>
    </Button>
  );
};

SapaButton.propTypes = {
  label: PropTypes.string,
  shape: PropTypes.oneOf(["circle", "default", "round"]),
  customClass: PropTypes.string,
  onClick: PropTypes.func,
  buttonType: PropTypes.string,
  htmlType: PropTypes.oneOf(["button", "submit", "reset"]),
  loading: PropTypes.bool,
};

SapaButton.defaultProps = {
  onClick: _noop,
  displayIcon: false,
  htmlType: 'button',
  loading: false
};

export default SapaButton;
