import React from "react";

const AddressDetails = ({data}) => {
  const {
    address,
    //rest
  } = data || {};


  const {
    address: residentialAddress = "N/A",
    country = "N/A",
    address_no: addressNo = "N/A",
    //city = "N/A",
    state = "N/A"
  } = address || {}

  return (
    <>
      <div className="users-modal-list__container">
        <div>
          <span>
            <p>Street Address</p>
            <p className="modal-list-dark-text">{residentialAddress}</p>
          </span>
          <span>
            <p>Apartment Suit no</p>
            <p className="modal-list-dark-text"> {addressNo}</p>
          </span>
          <span>
            <p>Country</p>
            <p className="modal-list-dark-text">{country}</p>
          </span>
          <span>
            <p>State</p>
            <p className="modal-list-dark-text">{state}</p>
          </span>
        </div>
      </div>
    </>
  );
};

export default AddressDetails;
