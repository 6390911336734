import React from "react";
import { Avatar, message, Typography } from "antd";
import { Header } from "antd/es/layout/layout";
import arrow from "../../assets/svgs/header-icon/arrow-down.svg";

import { Menu, Dropdown } from "antd";
import ArrowBackIcon from "../../assets/svgs/svg-icons/ArrowBackIcon";
import { useDispatch } from "react-redux";
import { logout } from './../../redux/actions/auth';
import { capitalizeWords, formatStr, sleep } from './../../utils/utils';
import { useNavigate } from "react-router-dom";

const SapaHeader = ({ page, showArrow }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //const { showOverview, setShowOverview } = useContext(SapaContext);

  const onLogoutRequest = () => {
    dispatch(logout()).then((response) => {
      message.success(response?.message?? 'Logged out successfully');
      sleep(1000).then(() => {
        navigate('/');
      });
    }).catch(err => message.error(err));
  }

  const menu = (
    <Menu selectable>
      <Menu.Item key={Math.random().toString()}>
        <a
          target="_self"
          rel="noopener noreferrer"
          onClick={onLogoutRequest}
        >
          Logout
        </a>
      </Menu.Item>
    
    </Menu>
  );
  return (
    <Header className="Header">
      <div className="sapa-header__title">
       {/*  {showArrow && (
          <ArrowBackIcon onClick={() => setShowOverview(!showOverview)} />
        )} */}
         {showArrow && (
          <ArrowBackIcon onClick={() => navigate(-1)} />
        )}
        <Typography.Text>{capitalizeWords(formatStr(page))}</Typography.Text>
      </div>
      <div className="sapa-header__profile">
        <Avatar className="sapa-header__profile_name">AA</Avatar>
        <Dropdown overlay={menu}>
          <a className="ant-dropdown-link" href="#">
            <img src={arrow} alt="/" />
          </a>
        </Dropdown>
      </div>
    </Header>
  );
};

SapaHeader.defaultProps = {
  showArrow: false,
};

export default SapaHeader;
