import { createPromiseAction } from '@adobe/redux-saga-promise';

// Actions for listing services
export const listServices = createPromiseAction('services/list');

// Actions for filtering services
export const filterServices = createPromiseAction('services/filter');

// Actions for searching services
export const searchServices = createPromiseAction('services/search');

// Actions for exporting services
export const exportServices = createPromiseAction('services/export');

export const switchProvider = createPromiseAction('services/switchProvider');
