// Selector to get the entire services state
export const selectServicesState = (state) => state.service;

// Selector to get the loading status for services actions
export const selectServicesLoading = (state) => state.service.loading;

// Selector to get the error for services actions
export const selectServicesError = (state) => state.service.error;

// Selector to get the list of services (includes filtered or search results)
export const selectServicesData = (state) => state.service?.services?.data?? [];

export const selectServicesPagination = (state) => ({
    currentPage: state.service.services.currentPage,
    totalRecords: state.service.services.totalRecords,
    pageSize: state.service.services.pageSize,
});


// Selector to get the loading status for export action
export const selectExportLoading = (state) => state.service.export.loading;

// Selector to get the success status for export action
export const selectExportSuccess = (state) => state.service.export.success;

// Selector to get the error for export action
export const selectExportError = (state) => state.service.export.error;

// Selector to get the loading status for export action
export const selectSwitchProviderLoading = (state) => state.service?.switchProvider?.loading;

