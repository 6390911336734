import { Avatar } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import React from "react";
import avatar from "../images/avatar.png";
import CheckCircle from "../../../../assets/svgs/svg-icons/Check-circle";
import CopyIcon from "../../../../assets/svgs/svg-icons/Copy-icon";

const BvnDetails = ({data}) => {
  const {
    user_profile: userProfile,
    tier,
    address,
    identity_verifications: kycs = [],
    //rest
  } = data || {};

  const {
    first_name: firstName = "N/A",
    last_name: lastName = "N/A",
    middle_name: middleName = "N/A",
    birth_date: birthDate = "N/A",
    gender = "N/A"
  } = userProfile || {};

  const {
    bvn = "N/A"
  } = tier || {}

  const {
    //address: residentialAddress = "N/A",
    country = "N/A",
    //city = "N/A",
    //state = "N/A"
  } = address || {}

  return (
    <>
      <Avatar className="modal-avatar" src={avatar} alt="/" />
      <div className="users-modal-list__container">
        <div>
          <span>
            <p>{kycs[0]?.identity_type?? 'BVN'}</p>
            <p className="modal-list-dark-text">{kycs[0]?.identity_number?? 'N/A'}</p>
          </span>
          <span>
            <p>First Name</p>
            <p className="modal-list-dark-text">{firstName}</p>
          </span>
          <span>
            <p>Middle Name</p>
            <Paragraph
              className="modal-list-dark-text"
              copyable={{
                icon: [
                  <CopyIcon key="copy-icon" />,
                  <CheckCircle key="copied-icon" />,
                ],
                text: {middleName},
              }}
            >
              {middleName}
            </Paragraph>
          </span>
          <span>
            <p>Last Name</p>
            <p className="modal-list-dark-text">{lastName}</p>
          </span>
          <span>
            <p>Date of Birth</p>
            <p className="modal-list-dark-text">{birthDate}</p>
          </span>
          <span>
            <p>Gender</p>
            <p className="modal-list-dark-text">{gender}</p>
          </span>
          <span>
            <p>Nationality</p>
            <p className="modal-list-dark-text">{country}</p>
          </span>
        </div>
      </div>
    </>
  );
};

export default BvnDetails;
