import apiClient from './apiClient';

// Request to list providers
export const listProvidersRequest = (data) =>
  apiClient({
    method: 'POST',
    url: '/providers',
    data
  });

// Request to search providers
export const searchProvidersRequest = (query) =>
  apiClient({
    method: 'GET',
    url: '/providers/search',
    params: { query },
  });

// Request to filter providers
export const filterProvidersRequest = (filters) =>
  apiClient({
    method: 'POST',
    url: '/providers/filter',
    data: filters,
  });

// Request to export providers
export const exportProvidersRequest = (format) =>
  apiClient({
    method: 'GET',
    url: `/providers/export`,
    params: { format },
  });

// Request to get provider info
export const getProviderInfoRequest = (id) =>
  apiClient({
    method: 'GET',
    url: `/providers/${id}`,
  });

// Request to update provider info
export const updateProviderInfoRequest = (id, data) =>
  apiClient({
    method: 'PUT',
    url: `/providers/${id}`,
    data,
  });

// Request to update provider balance
export const updateProviderBalanceRequest = (data) => {
  const {id, ...payload} = data || {};

  return apiClient({
    method: 'PUT',
    url: `/providers/${id}/balance`,
    data: payload,
  });
}

// Request to add a provider
export const addProviderRequest = (data) =>
  apiClient({
    method: 'POST',
    url: '/providers',
    data,
  });
