import { Card, Table } from "antd";
import React, { useEffect } from "react";
import SapaHeader from "../../ui/Header/Header";
import Filterbox from "../../ui/FilterBox/Filterbox";
import { columns } from "../../DummyAPI/TableData/Columns";
import ProgressLoader from "../../components/Loader/ProgressLoader";
import { useDispatch, useSelector } from "react-redux";
import { selectTransactionList, selectTransactionPagination, selectTransactionsLoading } from './../../redux/selectors/transactionSelectors';
import { listTransactions } from "../../redux/actions/transaction";
import { formatDate } from "../../utils/utils";
import SpPagination from "../../components/SpPagination/SpPagination";


const Transactions = ({ showHeader, type, data: userData }) => {
  const dispatch = useDispatch();

  const transactionList = useSelector(selectTransactionList);
  const isLoading = useSelector(selectTransactionsLoading);
  const txPagination = useSelector(selectTransactionPagination);

  const { totalRecords = 20, pageSize = 10, currentPage = 1 } = txPagination || {};

  const tableStyle = {
    tableLayout: "fixed",
  };


  // adding classname to odd table rows
  const rowClassName = (record, index) => {
    return index % 2 === 1 ? "odd-row" : "";
  };

  const columnKeys = columns.map((column) => column.key);

  useEffect(() => {
    dispatch(listTransactions({ user_id: userData?.id }));
  }, [dispatch, userData?.id]);

  const prepareDataToExport = () => {
    let data = transactionList.map(item => {
      return {
        "Full Name": item?.user?.user_profile?.first_name + " " + item?.user?.user_profile?.last_name,
        "Account ID": item?.user?.account_id,
        "Email": item?.user?.email,
        "Phone Number": item?.user?.phone_number,
        "Service Type": item?.service?.name,
        "Reference": item?.transaction_reference,
        "External Reference": item?.external_reference,
        "Currency": item?.currency,
        "Amount": item?.amount,
        "Transaction Fee": item?.transaction_fee,
        "Bal. Before": item?.pre_balance,
        "Bal. After": item?.post_balance,
        "Payment Method": item?.payment_method,
        "Processor": item?.provider?.name,
        "Transaction Date": formatDate(item?.created_at)
      };
    });

    return data;
  };

  return (
    <ProgressLoader isLoading={isLoading}>
      <div className="Transactions">
        {showHeader && <SapaHeader page={"Transactions"} />}
        <Card className="sapa-transactions__container">
          <Filterbox
            type={type}
            searchAction={listTransactions}
            searchIsLoading={isLoading}
            searchActionData={{user_id: userData?.id}}
            applyFilterAction={listTransactions}
            dataToExport={prepareDataToExport}
            exportFileName="transactions.csv"
          />
          <Table
            key={columnKeys}
            dataSource={transactionList}
            columns={columns}
            pagination={false}
            rowClassName={rowClassName}
            style={tableStyle}
            
          />
          <SpPagination 
            defaultCurrent={currentPage}
            totalRecords={totalRecords} 
            pageSize={pageSize}
            onChange={(value) => {
              dispatch(listTransactions({nextPage: value, user_id: userData?.id}));
            }} 
          />
        </Card>
      </div>
    </ProgressLoader>
  );
};

Transactions.defaultProps = {
  showHeader: true,
};

export default Transactions;
